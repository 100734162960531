:root {
    --required-red: #e32;
    --theme-color-bright: #32C896;
    --page-background: #F5F6F6;
    --form-background: white;
}


@font-face {
    font-family: 'sd-avenir';
    src: url(./fonts/Avenir/300-Avenir-Light-07.woff) format('woff');
    font-weight: 300;
}

@font-face {
    font-family: 'sd-avenir';
    src: url(./fonts/Avenir/400-AvenirLTStd-Book.woff) format('woff');
}

@font-face {
    font-family: 'sd-avenir';
    src: url(./fonts/Avenir/600-Avenir-Medium-09.woff) format('woff');
    font-weight: 600;
}

@font-face {
    font-family: 'sd-avenir';
    src: url(./fonts/Avenir/700-Avenir-Heavy-05.woff) format('woff');
    font-weight: 700;
}

@font-face {
    font-family: 'sd-avenir';
    src: url(./fonts/Avenir/800-AvenirLTStd-Black.woff) format('woff');
    font-weight: 800;
}

body {
    background-color: var(--page-background);
    font-family: sd-avenir, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

form .section {
    background-color: var(--form-background);
    padding-bottom: 35px !important;
}

.section .row .form-check {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.required:after {
    content: " *";
    display: inline;
    color: var(--required-red);
}

.companyLogo {
    max-width: 70px;
    max-height: 70px;
}

.place-order-btn .btn {
    font-size: 18px !important;
    padding: 10px 20px !important;
    color: white !important;
    background-color: var(--theme-color-bright);
}

.place-order-btn .btn:hover {
    color: black !important;
    box-shadow: 0px 6px 20px 10px #00000020 !important
}

.loadingContainer {
    opacity: 0.5;
    pointer-events: none;
}

.loadingContainer .spinner-border {
    position: fixed;
    top: 35%;
    left: calc(50% - 1.5rem);
    height: 3rem;
    width: 3rem;
    opacity: 1;
}

.cur-pointer {
    cursor: pointer;
}

.custom-method-input {
    min-height: 38px;
    gap: 8px
}
