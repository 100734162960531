#addressCheckerModal .modal-header {
    text-align: center;
}

#addressCheckerModal .modal-title {
    font-size: 1.4rem;
}

#addressCheckerModal .modal-body {
    padding: 25px !important;
}

.addrPlug-update-btn {
    position: absolute;
    font-size: 1rem;
    font-weight: 700;
    line-height: 45px;
    padding: 0 15px;
    border-radius: 8px;
    height: 48px;
    bottom: 30px;
    left: calc(50% - 50px);
    width: fit-content;
    width: -moz-fit-content;
    z-index: 999;
    text-align: center;
}

.addrPlug-update-btn span {
    width: 100%;
}

.addrPlug-update-btn.show {
    bottom: 30px;
    opacity: 1;
    transition: all .1s ease-in-out;
}

.addrPlug-update-btn.hide {
    bottom: -10px;
    opacity: 0;
    pointer-events: none;
    transition: all .1s ease-in-out;
}

.addressPluginMapPointerBtn {
    z-index: 401;
    position: absolute;
    right: 35px;
    bottom: 125px;
    width: 35px;
    border-radius: 5px;
    padding: 5px;
    border: 2px solid rgba(0,0,0,0.2);
    background-clip: padding-box;
}

.addressPluginMapPointerBtn:hover {
    background-color: #f4f4f4 !important;
}

.map-toggle-btn {
    margin-left: 5px;
    padding: 2px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: none;
    -webkit-appearance: none !important;
    appearance: none !important;
    outline: none !important;
    text-shadow: none !important;
    transition: box-shadow .3s ease-in-out;
}

.map-toggle-btn:hover {
    box-shadow: 3px 3px 6px 0px #0000001f;
}

/*.addrPlug-update-btn .loader {*/
/*    border: 3px solid #f3f3f3;*/
/*    border-top: 3px solid #428bca;*/
/*    border-radius: 17px;*/
/*    width: 25px;*/
/*    height: 20px;*/
/*    margin-right: 5px;*/
/*    animation: spin-address-plugin 2s linear infinite;*/
/*    align-items: center;*/
/*    align-content: center;*/
/*    margin-top: 10px;*/
/*}*/

@keyframes spin-address-plugin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.materialUI-form-group {
    margin-top: -25px;
}

.materialUI-form-group>label {
    bottom: -11px;
    left: 15px;
    position: relative;
    background-color: white;
    padding: 0px 5px 0px 5px;
    font-size: 0.8em;
    transition: 0.2s;
    pointer-events: none;
    font-weight: 500;
    color: #333;
}

.materialUI-form-control:focus~label {
    bottom: -8px;
}

.materialUI-form-control:valid~label {
    bottom: -8px;
}

.materialUI-form-control {
    outline: none;
    color: #333;
    border-color: #333 !important;
    font-size: 1rem;
    padding: 12px 15px;
    border-radius: 8px;
    font-weight: 400;
    resize: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 48px;
}

.materialUI-form-control:focus, .materialUI-form-control:valid {
    outline: none;
    border-color: #333 !important;
    box-shadow: none;
}

.addressPluginSuggestions {
    position: absolute;
    z-index: 999;
    background: white;
    border: 1px solid #0000004a;
    min-width: calc(100% - 50px);
    padding: 10px;
    margin-right: 25px;
    margin-top: 1px;
    box-shadow: 3px 3px 10px 3px #00000026;
    border-radius: 8px;
}

.addressPluginSuggestions.hide {
    display: none;
}

.addressPluginSuggestions.show {
    display: block;
}

#addressPluginInputBar:focus + .addressPluginSuggestions {
    z-index: 999 !important;
}

#addressPluginInputBar:not(focus) + .addressPluginSuggestions {
    z-index: -1 !important;
    transition: z-index .5s ease-in-out;
}

.addressPluginSuggestions:first-child {
    border-top: none;
}

.addressPluginSuggestionsChild {
    padding: 5px 0;
    border-top: 1px solid #00000017;
    cursor: pointer;
}

.addressPluginSuggestionsChild:first-child {
    border-top: none;
}
