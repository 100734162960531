.wickedpicker {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-shadow: 0 0 0 1px rgba(14, 41, 57, 0.12), 0 2px 5px rgba(14, 41, 57, 0.44), inset 0 -1px 2px rgba(14, 41, 57, 0.15);
    background: #fefefe;
    margin: 0 auto;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 1rem;
    display: none;
}

.wickedpicker__title {
    display: none;
}

.wickedpicker__controls {
    padding: 10px 0;
    line-height: normal;
    margin: 0;
}

.wickedpicker__controls__control, .wickedpicker__controls__control--separator {
    vertical-align: middle;
    display: inline-block;
    font-size: inherit;
    margin: 0 auto;
    width: 35px;
    letter-spacing: 1.3px;
}

.wickedpicker__controls__control-up, .wickedpicker__controls__control-down {
    color: #34495e;
    position: relative;
    display: block;
    margin: 3px auto;
    font-size: 18px;
    cursor: pointer;
}

.wickedpicker__controls__control-up:before {
    content: "\2303";
}

.wickedpicker__controls__control-down:after {
    content: "\2303";
    display: inline-block;
    transform: rotate(180deg);
}

.wickedpicker__controls__control--separator {
    width: 5px;
}

.text-center, .wickedpicker__title, .wickedpicker__controls, .wickedpicker__controls__control, .wickedpicker__controls__control--separator, .wickedpicker__controls__control-up, .wickedpicker__controls__control-down {
    text-align: center;
}

.hover-state {
    color: #3498db;
}


