.paidForm{
    background-color: #f6f6f6;
}

.paidForm .roundedBtn {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.paidForm .paid-card {
    border-radius: 8px;
    margin: 24px 0;
    padding: 24px;
    box-shadow: 0 1px 1px rgba(0,0,0,.15);
    height: fit-content;
    width: 100%;
}

.paidForm .fw-bold-sm {
    font-weight: 500 !important;
}

.paidForm .secondary-text {
    font-size: 14px;
    line-height: 22px;
    overflow-wrap: break-word;
}


.paidForm textarea, .paidForm  input[type = email], .paidForm  input[type = text], .paidForm  input[type = number] {
    background-color: rgba(0,0,0,.05) !important;
    border: 1px solid transparent !important;
    border-radius: 8px !important;
    color: rgba(0,0,0,.7) !important;
    font-size: 16px !important;
    height: 48px !important;
    line-height: 1.2 !important;
    margin-bottom: 8px !important;
    padding: 16px !important;
    text-overflow: ellipsis !important;
    transition: border-color .15s ease !important;
    width: 100% !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    outline: none !important;
    text-shadow: none !important;
    overflow-y: scroll;
}

.paidForm textarea:focus, .paidForm  input[type = email]:focus, .paidForm  input[type = text]:focus, .paidForm  input[type = number]:focus {
    border: 1px solid black !important;
    outline: none 0 !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.paidForm  textarea:hover, .paidForm  input[type = email]:hover, .paidForm  input[type = text]:hover, .paidForm  input[type = number]:hover {
    border: 1px solid rgba(171, 170, 170, 0.81) !important;
    outline: none 0 !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.paidForm  .input-card-title {
    color: rgba(0,0,0,.55);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .05em;
    line-height: 20px;
    margin: 0 0 2px;
    text-transform: uppercase;
}

.paidForm .logoBar{
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    height: 100%;
    margin: 0 auto;
    max-width: 1048px;
}

.paidForm .logoBar .fs-5 {
    font-size: 1.1rem !important;
}

@media (max-width: 820px) {
    .paidForm .logoBar {
        grid-template-columns: 0.3fr 5fr 0fr
    }
    .paidForm .btnEdit .btnText {
        display: none;
    }
    .paidForm .container-md {
        padding: 0 !important;
    }
}

.paidForm .logoBarDiv {
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
    height: 72px;
}

.paidForm .name {

}

.paidForm .btnPay {
    align-items: center;
    background: none;
    background: var(--theme-color-bright);
    border: 0;
    border-radius: 30px;
    color: #fff;
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    justify-content: center;
    line-height: 32px;
    outline: none;
    width: 100%;
}

.paidForm .btnEstimate {
    background-color: black !important;
}

.paidForm .btnEdit {
    cursor: pointer;
}

.paidForm .btnEdit:hover {
    color: gray;
}

.paidForm .hr-line {
    margin-left: -24px !important;
    margin-right: -24px !important;
}


.paidForm  textarea::placeholder, .paidForm  input[type = email]::placeholder, .paidForm  input[type = text]::placeholder, .paidForm  input[type = number]::placeholder {
    color: rgba(0,0,0,.7);
    opacity: 1;
}

.paidForm  textarea::-ms-input-placeholder, .paidForm  input[type = email]::-ms-input-placeholder, .paidForm  input[type = text]::-ms-input-placeholder, .paidForm  input[type = number]::-ms-input-placeholder {
    color: rgba(0,0,0,.7)
}

.paidForm div[disabled] {
    pointer-events: none;
    opacity: 0.7;
}

.paidForm .map-toggle-btn {
    margin-bottom: 8px !important;
    transition: border-color .15s ease !important;
    background-color: rgba(0,0,0,.05) !important;
}

.paidForm .map-toggle-btn:hover {
    border: 1px solid rgba(171, 170, 170, 0.81) !important;
    outline: none 0 !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

/*.paidForm .map-toggle-btn {*/
/*    display: none !important;*/
/*}*/

.paidForm select {
    background-color: rgba(0,0,0,.05) !important;
    border: 1px solid transparent !important;
    border-radius: 8px !important;
    color: rgba(0,0,0,.7) !important;
    font-size: 16px !important;
    height: 48px !important;
    line-height: 1.2 !important;
    margin-bottom: 8px !important;
    padding: 16px 16px 12px !important;
    text-overflow: ellipsis !important;
    transition: border-color .15s ease !important;
    width: 100% !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    outline: none !important;
    text-shadow: none !important;
    overflow-y: scroll;
    cursor: pointer;
}

.paidForm .custom-img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 8px;
}

.paidForm .custom-img img {
    width: 100%;
    border-radius: 8px;
}

#addressPlug-modal-dialog {
    border-radius: 12px;
}
